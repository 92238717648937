<template>
  <v-app id="Gate">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'GateIndex',

  data: () => ({}),
}
</script>

<style lang="sass">
#Gate
  background: #ffffff
</style>
